<template>
    <!-- 编辑人员弹框 -->
    <el-dialog width="30%" :close-on-click-modal="false" top="6vh"
               :visible.sync="panelShow" :before-close="cancelEmployeeSave">
        <template slot="title">
            <title-icon/>{{ employeeTitle }}
        </template>
        <el-form :model="employee.form" ref="employeeForm" :rules="employee.formRules">
            <el-form-item style="text-align:center">
                <img :src="employee.form.photo" class="avatar" :onerror="defaultAvatar"/>
            </el-form-item>
            <el-form-item :label="$t('organization.employee.user')" prop="userId">
                <template v-if="isCreate==true">
                    <account-select class="regionSelect" v-model="employee.form.userId" valueField="userId" @change="employeeChange"></account-select>
                </template>
                <template v-else>
                    <el-input class="hidden" v-model="employee.form.userId" readonly></el-input>
                    <el-input v-model="employee.form.userAccount" readonly></el-input>
                </template>
            </el-form-item>
            <el-form-item :label="$t('organization.employee.userName')">
                <el-input v-model="employee.form.userName" readonly></el-input>
            </el-form-item>
            <el-form-item :label="$t('organization.employee.phone')">
                <el-input v-model="employee.form.phone" readonly></el-input>
            </el-form-item>
            <el-form-item :label="$t('organization.employee.email')">
                <el-input v-model="employee.form.email" readonly></el-input>
            </el-form-item>
            <el-form-item :label="$t('organization.employee.organiza')">
                <el-cascader
                        class="regionSelect"
                        v-model="employee.form.organiza"
                        :placeholder="$t('commons.selectPlease')"
                        :options="orgCascadeList"
                        filterable
                        change-on-select
                ></el-cascader>
            </el-form-item>
        </el-form>
        <div slot="footer">
            <el-button @click="cancelEmployeeSave">{{$t('commons.cancel') }}</el-button>
            <el-button type="primary" @click="handleEmployeeSave" :disabled='isHttping'>
                {{$t('commons.save') }}
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {orgCascade} from '@/api/ruge/organization/tenant/organization'
    import AccountSelect from '@/components/AccountSelect'
    import {
        insertEmployee,
        updateEmployee,
       //getUserList,
        isBindUser
    } from '@/api/ruge/organization/tenant/employee'

    import {envInfo} from '@/constants/envInfo'

    export default {
        name: 'EditTenantEmployee',
        components: {
            AccountSelect
        },
        props: {
            getEmployeesPage: {
                type: Function
            },
            isCreate: {
                type: Boolean
            },
            //是否显示
            panelShow: {
                type: Boolean
            },
            // 当前人员
            employeeForm: {
                type: Object,
            },
        },
        data() {
            return {
                defaultAvatar: 'this.src="' + require('@/assets/images/defaultAvatar.png') + '"',
                employeeTitle: null,
                employeeList: [],
                orgCascadeList: [],
                isHttping:false,
                employee: {
                    form: {
                        userId: null,
                        empId: null,
                        userName: null,
                        userAccount: null,
                        deptId: null,
                        deptName: null,
                        dutyId: null,
                        dutyName: null,
                        companyId: null,
                        companyName: null,
                        phone: null,
                        email: null,
                        organiza: null,
                        photo: '',
                    },
                    formRules:{
                        userId: [
                            {
                                required: true,
                                trigger: ['blur', 'change'],
                                message: this.$t('validate.required')
                            }
                        ],
                    },
                },

            }
        },
        computed: {
            currentEmployee() {
                this.initEmployeeModalData();
                return this.employeeForm;
            },
            isEmpCreate() {
                return this.isCreate;
            }
        },
        watch: {
            currentEmployee: function () {
                this.currentUser();
            },
            isEmpCreate: function () {
                this.isCreateEmp();
            }
        },
        methods: {
            currentUser() {
                if (this.currentEmployee != null) {
                    this.employee.form = this.currentEmployee;
                } else {
                    this.clearEmployeeForm();
                    this.employee.form.organiza = this.currentEmployee.organiza;
                }
            },
            isCreateEmp() {
                if (this.isCreate) {
                    this.employeeTitle = this.$t('organization.employee.add');
                } else {
                    this.employeeTitle = this.$t('organization.employee.edit');
                }
            },
            initEmployeeModalData() {
                orgCascade().then(cascadeList => {
                    cascadeList.forEach(function (obj) {
                        obj.label = obj.companyName;
                        obj.value = obj.companyId;
                        if (obj.children.length != 0) {
                            obj.children.forEach(function (data) {
                                data.label = data.deptName;
                                data.value = data.deptId;
                                if (data.children.length != 0) {
                                    data.children.forEach(function (msg) {
                                        msg.label = msg.dutyName;
                                        msg.value = msg.dutyId;
                                    });
                                } else {
                                    data.children = undefined;
                                }
                            });
                        } else {
                            obj.children = undefined;
                        }
                    });
                    this.orgCascadeList = cascadeList;
                })
            },
            cancelEmployeeSave() {
                this.$refs.employeeForm.resetFields();
                // 下面的语句配合开头写的 .sync 就会更新父组件中的 panelShow 变量
                this.$emit('update:panelShow', false)  //弹框隐藏，意为panelShow为false
            },
            handleEmployeeSave() {
                this.$refs.employeeForm.validate(v=> {
                    if (v) {
                        this.employee.form.companyId = this.employee.form.organiza[0];
                        this.employee.form.deptId = this.employee.form.organiza[1];
                        this.employee.form.dutyId = this.employee.form.organiza[2];
                        this.isHttping=true;
                        if (this.isCreate) {
                            insertEmployee(this.employee.form).then(() => {
                                this.$emit('update:panelShow', false)
                                // 成功提示
                                this.$message({
                                    message: this.$t('message.saveSuccess'),
                                    type: 'success'
                                });
                                this.getEmployeesPage();
                            }).catch((error) => {
                                console.log(`保存失败，原因 => ${error}`)
                            }).finally(()=>{
                            	setTimeout(()=>{
                            		this.isHttping=false;
                            	},200)
                            	
                            });
                        } else {
                            updateEmployee(this.employee.form).then(() => {
                                this.$emit('update:panelShow', false)
                                // 成功提示
                                this.$message({
                                    message: this.$t('message.saveSuccess'),
                                    type: 'success'
                                });
                                this.getEmployeesPage();
                            }).catch((error) => {
                                console.log(`保存失败，原因 => ${error}`)
                            }).finally(()=>{
                            	setTimeout(()=>{
                            		this.isHttping=false;
                            	},200)
                            	
                            });
                        }
                    }
                });
            },
            employeeChange(selected) {
                isBindUser({"userAccount":selected.userAccount}).then((res) => {
                    if(res == 0){
                        this.employee.form.photo = envInfo.bgApp.ssoPath + "/param/avatar?u=" + selected.userAccount;
                        this.employee.form.phone = selected.phone;
                        this.employee.form.email = selected.email;
                        this.employee.form.userName = selected.userName;
                        this.employee.form.userAccount = selected.userAccount;
                    }else{
                        this.clearEmployeeForm();
                        this.$message({
                            message: selected.userAccount+this.$t('organization.employee.prompt'),
                            type: 'info'
                        });
                    }
                })
            },
            clearEmployeeForm() {
                this.employee.form = {
                    userId: null,
                    empId: null,
                    userName: null,
                    userAccount: null,
                    deptId: null,
                    deptName: null,
                    dutyId: null,
                    dutyName: null,
                    companyId: null,
                    companyName: null,
                    phone: null,
                    email: null,
                    photo: '',
                };
            }
        }
    }
</script>

<style scoped>
    .regionSelect {
        width: 100%;
    }

    .avatar {
        width: 80px;
        height: 80px;
        border-radius: 10px;
    }
</style>
