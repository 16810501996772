import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 人员查询
 * @param params
 */
export function getEmployeePage(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/tenant/employee/empPage',
    method: 'get',
    params,
  });
}

/**
 * 加载公司列表
 * @param params
 */
export function getCompanyList(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/tenant/company/list',
    method: 'get',
    params,
  });
}

/**
 * 根据公司id加载部门列表
 * @param params
 */
export function getDeptList(params) {
  return request({
    url: envInfo.bgApp.organizationPath + `/tenant/dept/list`,
    method: 'get',
    params,
  });
}

/**
 * 根据部门id加载岗位列表
 * @param params
 */
export function getDutyList(params) {
  return request({
    url: envInfo.bgApp.organizationPath + `/tenant/duty/list`,
    method: 'get',
    params,
  });
}

/**
 * 新增人员
 * @param params
 */
export function insertEmployee(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/tenant/employee/create',
    method: 'post',
    data: params,
  });
}

/**
 * 修改人员
 * @param params
 */
export function updateEmployee(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/tenant/employee/update',
    method: 'post',
    data: params,
  });
}

/**
 * 删除人员
 * @param params
 */
export function deleteEmployee(params) {
  return request({
    url:
      envInfo.bgApp.organizationPath +
      `/tenant/employee/delete/${params.userId}`,
    method: 'delete',
  });
}

/**
 * 导出人员
 * @param params
 */
export function exportEmployee(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/tenant/employee/export',
    method: 'post',
    data: params,
  });
}

/**
 * 按条件查询人员
 * @param params
 */
export function findEmployee(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/tenant/employee/empList',
    method: 'get',
    params,
  });
}

//获取所有用户
export function getUserList() {
  return request({
    url: envInfo.bgApp.tenantPath + '/tenant/user/userList',
    method: 'get',
  });
}

//校验是否绑定人员账号
export function isBindUser(params) {
  return request({
    url:
      envInfo.bgApp.organizationPath +
      '/tenant/employee/ifBind?userAccount=' +
      params.userAccount,
    method: 'get',
  });
}

export function getEmployeeTree(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/tenant/employee/getEmployeeTree',
    method: 'get',
  });
}
