<template>
    <div class="app-container">
        <el-form role="form" label-width="50px">
            <el-row>
                <el-col :span="5">
                    <el-form-item :label="$t('organization.company.name')">
                        <el-select @change="changeCompany" clearable v-model="listQuery.companyId" :style="{width:'96%'}">
                            <el-option
                                    v-for="item in companyList"
                                    :value="item.companyId"
                                    :key="item.companyId"
                                    :label="item.companyName">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item :label="$t('organization.dept.name')">
                        <el-select @change="changeDept" clearable v-model="listQuery.deptId" :style="{width:'96%'}">
                            <el-option
                                    v-for="item in deptList"
                                    :value="item.deptId"
                                    :key="item.deptId"
                                    :label="item.deptName">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item :label="$t('organization.dept.dutyName')">
                        <el-select @change="searchAgain" clearable v-model="listQuery.dutyId" :style="{width:'96%'}">
                            <el-option
                                    v-for="item in dutyList"
                                    :value="item.dutyId"
                                    :key="item.dutyId"
                                    :label="item.dutyName">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item :label="$t('organization.employee.user')">
                        <account-select v-model="listQuery.userAccount" :style="{width:'96%'}"></account-select>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-button @click="searchAgain" type="primary" icon="el-icon-search">{{$t('commons.search')}}</el-button>
                </el-col>
            </el-row>
            <el-row>
                <el-button @click="addEmployee" icon="el-icon-plus" plain size="mini" type="primary">{{$t('organization.employee.add')}}</el-button>
            </el-row>
        </el-form>
        <el-table
                v-loading="listLoading"
                :data="list"
                element-loading-text="loading"
                border
                fit
                highlight-current-row
                stripe>
            <el-table-column prop="userAccount" width="120" :label="$t('organization.employee.user')">
                <template slot-scope="s">
                    <span>{{ s.row.userAccount }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="userName" width="120" :label="$t('organization.employee.userName')">
                <template slot-scope="s">
                    <span>{{ s.row.userName }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('organization.employee.phone')" width="150">
                <template slot-scope="s">
                    <span>{{ s.row.phone }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('organization.company.name')">
                <template slot-scope="s">
                    <span>{{ s.row.companyName }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('organization.dept.name')">
                <template slot-scope="s">
                    <span>{{ s.row.deptName }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('organization.dept.dutyName')">
                <template slot-scope="s">
                    <span>{{ s.row.dutyName }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="100" :label="$t('commons.actions')">
                <template slot-scope="s">
                    <el-tooltip :content="$t('commons.edit')" placement="bottom" :open-delay="openDelay">
                        <el-button
                                size="mini"
                                icon="el-icon-edit"
                                type="success"
                                circle
                                :disabled="s.row.processing"
                                @click="updateEmployee(s.row)">
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :content="$t('commons.delete')" placement="bottom" :open-delay="openDelay">
                        <el-button
                                size="mini"
                                icon="el-icon-delete"
                                type="danger"
                                circle
                                :disabled="s.row.processing"
                                @click="deleteEmployee(s.row.userId)">
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <pagination v-show="total>0" :total="total" :page.sync="listQuery.current"
                    :limit.sync="listQuery.rowCount"
                    @pagination="getEmployeesPage"/>

        <!-- 编辑人员弹框 -->
        <edit-tenant-employee :panelShow.sync="panelShow" :employeeForm="templateEmployee" :isCreate="isCreate" :getEmployeesPage="getEmployeesPage"/>
    </div>
</template>

<script>
    import{
        getEmployeePage,
        deleteEmployee,
        getCompanyList,
        getDeptList,
        getDutyList,
        //getUserList
    } from '@/api/ruge/organization/tenant/employee'
    import Pagination from '@/components/Pagination'
    import AccountSelect from '@/components/AccountSelect'
    import { envInfo } from '@/constants/envInfo'
    import {EditTenantEmployee} from '@/views/ruge/employee/tenant/components'

    export default {
        name: "TenantEmployeeManager",
        components:{
            Pagination,
            AccountSelect,
            EditTenantEmployee
        },
        data(){
            return{
                openDelay:1000,
                panelShow: false,
                templateEmployee:null,
                isCreate:false,
                list:null,
                total: 0,
                listLoading: true,
                options:[],
                listQuery:{
                    //分页参数
                    current:1,
                    rowCount:10,
                    //模糊查询里的条件
                    userName: null,
                    userAccount: null,
                    phone: null,
                    companyName: null,
                    deptName: null,
                    dutyName: null,
                    companyId: null,
                    deptId: null,
                    dutyId: null,
                },
                userList: [],
                companyList: [],
                deptList:[],
                dutyList:[],
                deptNameList:[],
                dutyNameList:[],
                form: {
                    companyId: null,
                    deptId: null,
                    deptName: null,
                    dutyId: null,
                    email: null,
                    phone: null,
                    userId: null,
                    userName: null,
                },
                //加载状态
                createLoading:false
            }
        },
        created(){
            this.searchAgain();
            this.getCompanyList();
            // this.getUserList();
        },
        methods:{
            //查询按钮
            searchAgain(){
                this.listQuery.current = 1;
                this.getEmployeesPage();
            },
            changeAccount(values, record) {
                this.listQuery.userAccount = record.userAccount
                this.searchAgain();
            },
            // getUserList(){
            //     getUserList().then(list => {
            //         let userList = [];
            //         list.forEach(function (obj) {
            //             let vo = {
            //                 userAccount: obj.userAccount,
            //                 userName: obj.userName+"("+obj.phone+")"
            //             }
            //             userList.push(vo);
            //         })
            //         this.userList = userList;
            //     })
            // },
            //查询公司
            getCompanyList(){
                getCompanyList().then(res=>{
                    this.companyList = res
                })
            },
            //公司切换
            changeCompany(){
                if(this.listQuery.companyId != ''){
                    getDeptList({"companyId":this.listQuery.companyId}).then(res=>{
                        this.deptList = res;
                        this.listQuery.deptId = null;
                        this.listQuery.dutyId = null;
                        this.searchAgain();
                    })
                }else{
                    this.listQuery.companyId = null;
                    this.deptList = [];
                    this.listQuery.deptId = null;
                    this.listQuery.dutyId = null;
                    this.searchAgain();
                }
            },
            //部门切换
            changeDept(){
                getDutyList({"deptId":this.listQuery.deptId}).then(res=>{
                    this.dutyList = res;
                    this.listQuery.dutyId = null;
                    this.searchAgain();
                })
            },
            //查询人员
            getEmployeesPage(){
                this.listLoading = true
                getEmployeePage(this.listQuery).then((res) =>{
                    this.list = res.rows.map(v =>{
                        return v
                    })

                    this.total = res.total;
                    this.listLoading = false;
                }).catch(error =>{
                    this.listLoading = false;
                    console.log(`查询失败，原因=> ${error}`)
                })
            },
            //新增人员
            addEmployee(){
                this.templateEmployee = {
                    userId: null,
                    empId : null,
                    userName : null,
                    userAccount : null,
                    deptId : null,
                    deptName : null,
                    dutyId : null,
                    dutyName : null,
                    companyId : null,
                    companyName : null,
                    phone : null,
                    email : null,
                    organiza:[],
                    photo:'',
                };
                this.isCreate = true;
                this.panelShow = true;
            },
            //修改
            updateEmployee(row){
                this.templateEmployee = row;
                let arr = [];
                arr.push(row.companyId);
                arr.push(row.deptId);
                arr.push(row.dutyId);
                this.templateEmployee.organiza = arr;
                this.templateEmployee.photo = envInfo.bgApp.ssoPath + "/param/avatar?u="+row.userAccount;
                this.isCreate = false;
                this.panelShow = true;
            },
            //删除
            deleteEmployee(userId){
                this.$confirm(this.$t('message.deleteConfirm'),this.$t('commons.warning'),{
                    confirmButtonText: this.$t('commons.confirm'),
                    cancelButtonText: this.$t('commons.cancel'),
                    type: 'warning'
                }).then(()=>{
                    deleteEmployee({"userId":userId}).then(()=>{
                        //成功提示
                        this.$message({
                            message: this.$t('message.deleteSuccess'),
                            type: 'success'
                        });
                        //删除成功后重新渲染table
                        this.searchAgain()
                    }).catch(error =>{
                        console.log(`删除失败，原因 => ${error}`)
                    })
                }).catch(error => {
                    console.log(`未删除，原因 => ${error}`)
                });
            },
        }
    }
</script>

<style scoped>
    .el-table{
        margin-top: 10px;
    }
</style>
