var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "30%",
        "close-on-click-modal": false,
        top: "6vh",
        visible: _vm.panelShow,
        "before-close": _vm.cancelEmployeeSave,
      },
      on: {
        "update:visible": function ($event) {
          _vm.panelShow = $event
        },
      },
    },
    [
      _c(
        "template",
        { slot: "title" },
        [_c("title-icon"), _vm._v(_vm._s(_vm.employeeTitle) + "\n    ")],
        1
      ),
      _c(
        "el-form",
        {
          ref: "employeeForm",
          attrs: { model: _vm.employee.form, rules: _vm.employee.formRules },
        },
        [
          _c("el-form-item", { staticStyle: { "text-align": "center" } }, [
            _c("img", {
              staticClass: "avatar",
              attrs: {
                src: _vm.employee.form.photo,
                onerror: _vm.defaultAvatar,
              },
            }),
          ]),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("organization.employee.user"),
                prop: "userId",
              },
            },
            [
              _vm.isCreate == true
                ? [
                    _c("account-select", {
                      staticClass: "regionSelect",
                      attrs: { valueField: "userId" },
                      on: { change: _vm.employeeChange },
                      model: {
                        value: _vm.employee.form.userId,
                        callback: function ($$v) {
                          _vm.$set(_vm.employee.form, "userId", $$v)
                        },
                        expression: "employee.form.userId",
                      },
                    }),
                  ]
                : [
                    _c("el-input", {
                      staticClass: "hidden",
                      attrs: { readonly: "" },
                      model: {
                        value: _vm.employee.form.userId,
                        callback: function ($$v) {
                          _vm.$set(_vm.employee.form, "userId", $$v)
                        },
                        expression: "employee.form.userId",
                      },
                    }),
                    _c("el-input", {
                      attrs: { readonly: "" },
                      model: {
                        value: _vm.employee.form.userAccount,
                        callback: function ($$v) {
                          _vm.$set(_vm.employee.form, "userAccount", $$v)
                        },
                        expression: "employee.form.userAccount",
                      },
                    }),
                  ],
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("organization.employee.userName") } },
            [
              _c("el-input", {
                attrs: { readonly: "" },
                model: {
                  value: _vm.employee.form.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.employee.form, "userName", $$v)
                  },
                  expression: "employee.form.userName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("organization.employee.phone") } },
            [
              _c("el-input", {
                attrs: { readonly: "" },
                model: {
                  value: _vm.employee.form.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.employee.form, "phone", $$v)
                  },
                  expression: "employee.form.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("organization.employee.email") } },
            [
              _c("el-input", {
                attrs: { readonly: "" },
                model: {
                  value: _vm.employee.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.employee.form, "email", $$v)
                  },
                  expression: "employee.form.email",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("organization.employee.organiza") } },
            [
              _c("el-cascader", {
                staticClass: "regionSelect",
                attrs: {
                  placeholder: _vm.$t("commons.selectPlease"),
                  options: _vm.orgCascadeList,
                  filterable: "",
                  "change-on-select": "",
                },
                model: {
                  value: _vm.employee.form.organiza,
                  callback: function ($$v) {
                    _vm.$set(_vm.employee.form, "organiza", $$v)
                  },
                  expression: "employee.form.organiza",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.cancelEmployeeSave } }, [
            _vm._v(_vm._s(_vm.$t("commons.cancel"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.isHttping },
              on: { click: _vm.handleEmployeeSave },
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.$t("commons.save")) + "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }